@import '../theme.scss';

#formBrokerPasswordSent {
  background-color: $color-light-grey;
  padding: 0 $standard-padding-x;

  .mainContent {
    > .inner {
      max-width: 1350px;
      min-height: 500px;
      background-color: $color-white;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      align-content: space-between;
      padding: 50px clamp(20px, 5vw, 50px);

      .content {
        width: 100%;

        .title {
          color: $color-blue;
          font-size: clamp(22px, 3vw, 24px);
          font-weight: 600;
          width: auto;
          display: inline-block;
          margin: 0 auto;

          &::after {
            width: 20%;
            display: block;
            content: '';
            background-color: $color-red-light;
            height: 2px;
            margin-top: 10px;
            border-bottom: unset;
            padding-top: 0;
          }
        }

        .copy {
          font-size: 14px;
          color: $color-blue;
          margin-top: 30px;
          padding: 20px;
          width: 100%;
          max-width: 390px;
          background-color: $color-blue-light;
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: $screenBreak-mobile) {
  #formBrokerPasswordSent {
    padding: 0;

    .mainContent {
      > .inner {
        .content {
          grid-template-columns: 100%;
          grid-template-areas: 'right' 'left';

          .left {
            max-width: unset;
            width: 100%;
            padding: 50px $standard-padding-x;
            text-align: center;

            .title {
              &::after {
                margin-left: auto;
              }
            }

            .copy {
              text-align: left;
            }
          }

          .right {
            height: unset;
            aspect-ratio: 164/135;
          }
        }
      }
    }
  }
}
